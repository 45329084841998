.input-table-container {
  max-height: 68vh;
  overflow: auto;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  overflow-x: auto;
}

.input-table .action-buttons {
  justify-content: unset;
  width: unset;
  align-items: center;
  justify-content: center;
}

/* Table */
.input-table {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

/* Table Header */
.input-table th {
  background-color: #f2f2f2;
  color: #333;
  text-align: left;
  padding: 6px;
  font-size: 14px;
  border: 1px solid var(--gray-4);
}

/* Table Body */
.input-table td {
  padding: 0px;
  border: 1px solid var(--gray-4);
  font-size: 12px;
}

/* Table Rows */
.input-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.input-table tr:hover {
  background-color: #f1f1f1;
}

.input-table input[type="text"] {
  border-radius: 0px;
  border: none;
}

/* Table container margin for responsive design
@media screen and (max-width: 600px) {
  .input-table table, 
  .input-table thead, 
  .input-table tbody, 
  .input-table th, 
  .input-table td, 
  .input-table tr {
    display: block;
  }

  .input-table th {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .input-table tr {
    border: 1px solid #ccc;
  }

  .input-table td {
    border: none;
    border-bottom: 1px solid #ddd;
    position: relative;
    padding-left: 50%;
    text-align: right;
  }

  .input-table td:before {
    position: absolute;
    top: 12px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
  }

  .input-table td:nth-of-type(1):before { content: "Active"; }
  .input-table td:nth-of-type(2):before { content: "Name"; }
  .input-table td:nth-of-type(3):before { content: "Option"; }
} */


/* FOR MOBILE */
@media screen and (max-width: 600px) {
  .input-table-container input {
    width: 90px;
  }
}