.multiple-choice-options-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;
  flex: 1 0 0;
  min-height: 240px;
  max-height: 240px;
  overflow-y: scroll;
  padding: 8px 24px;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  border: 2px solid var(--gray-7);
  background: var(--pure-white);
}