.user-info-dropdown-row {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  gap: 10px;
  padding: 6px 9px;
  border-bottom: 1px solid var(--gray-7);
  justify-content: space-between;
}

.user-info-dropdown-row:hover {
  background-color: var(--gray-2);
}

.user-display-name {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 75%;
}

.user-info-dropdown-row .col {
  align-items: center;
}

.user-info-dropdown-row input[type='number'] {
  border: none !important;
  background-color: var(--pure-white);
  padding: 0px 0px 0px 0px ;
  margin: 0;
  gap: 0px;
  width: 40px;
}

.user-info-dropdown-row input[type='number']:focus-visible {
  outline: none;
}

.user-display-name select {
  width: 80px;
  max-width: 80px;
}