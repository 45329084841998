.inline-value-editor {
  display: inline-flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.inline-value-editor select {
  border: none;
  padding: 0px 0px 0px 0px;
}

.inline-value-editor select:focus-visible {
  outline: none
}

.inline-value-editor input {
  border: none;
  padding: 0px 0px 0px 0px;
}

.inline-value-editor input:focus-visible {
  outline: none
}

.inline-value-editor .buttons {
  display: inline-flex;
  flex-direction: row;
  gap: 0px;
}

.inline-value-editor .editor {
  display: inline-flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
}