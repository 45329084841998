.collapsable-filter {

}

.filter-item {
  display: flex;
  padding: 1px 0px 0px 31px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.filter-content {
  padding-left: 10px;
}