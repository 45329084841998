.file-input {
  padding: 5px;
  display: flex;
  padding: 5px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--pure-white);
  height: fit-content;
  min-width: fit-content;
  max-width: 100%;
}

.file-input img {
  width: 20px !important;
}

.file-upload-button {
  display: flex;
  padding: 8px 12px;
  height: 52px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 7px 4px 4px 7px;
  border-bottom: 1px solid rgba(82, 75, 73, 0.30);
  background: var(--gray-2);
  width: 100%;
  min-width: fit-content;
}

.file-upload-button:hover {
  cursor: pointer;
}

input#file-upload {
  opacity: 0;
  max-width: 0px;
}

.file-input.inline {
  align-self: unset;
}

.file-input.inline .file-upload-button {
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 4px;
  background: var(--gray-2);
  border: none;
  height: fit-content;
  width: fit-content;
}