.login-page {
  display: flex;
  width: 100vw;
  min-height: fit-content;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  background: var(--pure-white);
}

.login {
  display: flex;
  width: 480px;
  max-width: 95vw;
  height: 650px;
  min-height: 600px;
  max-height: 95vh;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36px;
  border-radius: 24px;
  background: var(--gray-1);
  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  /* Shadow/Section/Main Card */
  box-shadow: 0px 20px 60px 0px rgba(82, 75, 73, 0.20);
}

.login img {
  max-width: 114px;
}

.login-title {
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 0.5px solid var(--gray-7);
}

.login-content {
  display: flex;
  padding: 0px 18px;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  align-self: center;
  width: 300px;
  overflow-y: auto;
  min-width: fit-content;
  max-width: 100vw;
}

.login-footer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* FOR MOBILE */
@media screen and (max-width: 600px) {
  .login {
    gap: 4vh;
    top: 10px;
    left: 10px;
    position: absolute;
    transform: none;
    margin: auto;
  }

  .login .row input {
    max-width: 35vw;
  }
}

/* FOR MOBILE */
@media screen and (max-width: 400px) {
  .login {
    min-height: 95vh;
    min-width: 95vw;
  }
}