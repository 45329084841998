.user-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}

.user-input .hint {
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
}

.user-input-header {
  display: flex;
  flex-direction: row;
  height: 24px;
  padding: 0px 6px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.user-input-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}

/* .user-input input:read-only {

} */

.user-input .hint {
  color: var(--dark-green);
}