.student-row-info {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}

.header-row-button {
  display: flex;
  padding: 20px 42px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  align-self: stretch;
  border-radius: 7px;
  background: var(--gray-2);
  color: var(--gray-10);
  max-height: 60px;
}

.header-row-button:hover {
  cursor: pointer;
}

/* FOR MOBILE */
@media screen and (max-width: 700px) { 
  .header-row-button {
    padding: 5px 10px;
  }
}