.conversation-frame {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.conversation-frame .wormy {
  width: 100px;
  height: auto;
}

.conversation-frame textarea {
  display: flex;
  flex-grow: 1;
}