.assignment-settings {
  width: 60vw;
  height: 45vh;
  min-height: fit-content;
  background-color: var(--pure-white);
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.assignment-settings-tools {
  display: flex;
  flex-direction: column;
  width: 50%;
  flex: 1 0 1;
  align-items: center;
  background-color: #FAFAF7;
  border-radius: 10px 10px 10px 10px;
  padding: 12px;
  overflow-y: auto;
}

.assignment-settings h3 {
  border-bottom: solid 2px #D4D4D4;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}

.assignment-settings-item {
  display: flex;
  flex-direction: column;
  flex: 1 0 1;
  width: 100%;
  border-bottom: solid 2px #D4D4D4;
  padding-bottom: 20px;
  padding-top: 20px;
  gap: 20px;
}

.assignment-settings-tools-footer {
  padding-top: 20px;
}

.assignment-settings-preview {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  gap: 10px;
  flex: 1 0 1;
  width: 50%;
  background: #FFFFFF;
  border: 2px solid #FCF095;
  border-radius: 10px 10px 10px 10px;
}

.assignment-settings-preview-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  flex: 1 0 1;
  background: #FCF095;
  border: 2px solid #FCF095;
}

.assignment-settings-preview-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  flex: 1 0 1;
  background: var(--pure-white);
  border: none;
  padding: 12px;
  overflow: hidden;
}

.assignment-settings-preview-content .reading-frame {
  flex: 1 0 1;
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  background: transparent;
  border: none;
  overflow-y: auto;
}