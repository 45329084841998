.book-preview {
  display: flex;
  padding: 12px 12px 12px 12px;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  flex: 1 0 0;
  border-radius: 12px;
  background: var(--gray-1);
  max-height: 60vh;
  width: 600px;
  height: 900px;
  min-height: 30vh;
  max-width: 80vw;
  overflow-y: auto;
}

.book-preview-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 45px;
  align-self: stretch;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.book-preview-details {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}

/* FOR MOBILE */
@media screen and (max-width: 400px) {
  .book-preview {
    width: 95vw;
    max-width: 95vw;
    min-width: 95vw;
    height: fit-content;
  }

  .book-preview-details {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }
}