.landing-page {
  display: flex;
  width: 100vw;
  min-height: fit-content;
  align-items: center;
  flex-direction: column;
  background: var(--Gray-Pure-White, #FFF);
  margin-top: 152px;
}

.landing-page-nav {
  display: flex;
  box-sizing: border-box;
  width: 100vw;
  height: 100px;
  padding: 0px 5vw;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: var(--Gray-Pure-White, #FFF);
}

.landing-page-nav img {
  width: 132px;
  height: 46px;
  flex-shrink: 0;
}

.landing-page-nav .actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
}

.landing-page hr {
  width: 80vw;
  height: 2px;
  background: var(--Gray-Gray-4, #D4D4D4);
}

.landing-page .welcome-content {
  height: fit-content;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8vh 70px 20vh 70px;
  box-sizing: border-box;
  border-bottom: 4px solid var(--Color-Dark-Green, #447D00);
  background: var(--Gradient, linear-gradient(180deg, #FFF 0%, #FDFFFC 33.33%, #DDFECB 66.67%, #BAFC95 100%));
}

.landing-page .hero {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  gap: 10vw;
  width: 85vw;
  flex-wrap: wrap;
  min-height: fit-content;
}

.landing-page .speech-bubble {
  left: 25%;
}

.hero .text-content {
  max-width: 50%;
  width: 35vw;
  align-items: flex-start;
}

.landing-page .hero video {
  width: 35vw;
  min-width: 380px;
}

.landing-page .reading-container {
  max-height: 60vh;
  min-height: 60vh;
  max-width: 35vw !important;
  width: 35vw !important;
  min-width: 300px !important;
}

.landing-page .missed-word-popup {
  max-width: 30vw !important;
}

.landing-page .reading-frame {
  max-height: 40vh !important;
  min-height: 40vh !important;
}

/* .hero .content img {
//   width: 40vw;
//   max-width: 500px;
//   min-width: 250px;
 }*/

.landing-page h1 {
  font-size: 50px;
}

.landing-page h1.green {
  color: var(--Color-Dark-Green, #447D00);
}

.landing-page h1.white {
  color: var(--Color-Dark-Green, #FFF);
}

.landing-page .summary-content {
  background: var(--Gray-Gray-1, #FAFAF7);
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10vh;
  text-align: center;
  padding: 10vh 70px;
  box-sizing: border-box;
}

.landing-page .summary-content .content {
  display: flex;
  width: 90vw;
  align-items: flex-start;
  align-content: flex-start;
  gap: 34px 3vw;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.landing-page .summary-content .content .card {
  display: flex;
  width: 28vw;
  flex-direction: column;
  align-items: center;
  gap: 3vh;
  flex-shrink: 0;
  color: var(--Gray-Pure-Black, #000);
  box-sizing: border-box;
}

.landing-page .demo-content {
  width: 100vw;
  height: fit-content;
  flex-shrink: 0;
  background: var(--Color-Dark-Green, #447D00);
  padding: 10vh 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10vh;
  box-sizing: border-box;
}

.landing-page .demo-content .text-content {
  color: white;
}

.landing-page .demo-content hr {
  width: 80vw;
  height: 2px;
  opacity: 0.5;
  background: var(--Color-Light-Green, #BAFC95);
}

.landing-page .horizontal-carousel {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 45px;
  width: 100vw;
  overflow-x: auto;
  height: fit-content;
  margin-left: 5vw;
  margin-right: 5vw;
  justify-self: center;
}

.landing-page .horizontal-carousel  img {
  width: 50vw;
  height: auto;
}

.landing-page .teacher-demo {
  display: inline-flex;
  align-items: center;
  gap: 95px;
  width: 80vw;
  flex-wrap: wrap-reverse;
}

.landing-page .teacher-demo .examples {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.landing-page .teacher-demo .examples img {
  width: 40vw;
  height: auto;
}

.landing-page .logistics-content {
  background: var(--Gray-Gray-1, #FAFAF7);
  width: 100vw;
  height: fit-content;
  flex-shrink: 0;
  padding: 10vh 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10vh;
  box-sizing: border-box;
}

.landing-page .text-content {
  text-align: left;
  max-width: 80vw;
  gap: 25px;
  display: flex;
  flex-direction: column;
}

.landing-page .faqs {
  text-align: left;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}

.landing-page .faqs .classroom-panel {
  width: 80vw;
  text-align: left;
  box-sizing: border-box;
}

.landing-page .faqs .classroom-panel.expanded {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background: var(--Color-Dark-Blue, #016D89);
}

.landing-page .faqs .classroom-panel .row {
  justify-content: space-between;
  width: 100%;
}

.landing-page .signup-content {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10vh;
  padding: 10vh 60px 10vh 60px;
  box-sizing: border-box;
  background: var(--Gradient, linear-gradient(180deg, #FFF 0%, #FDFFFC 33.33%, #DDFECB 66.67%, #BAFC95 100%));
}

.social-media-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80vw;
  overflow-x: auto;
  gap: 10vw;
  box-sizing: border-box;
}

.landing-page .signup-header {
  width: 80vw;
  max-width: 1250px;
  height: fit-content;
  border-radius: 25px;
  background: var(--Color-Dark-Green, #447D00);
  padding: 40px 60px 40px 60px;
  display: flex;
  flex-direction: row;
  gap: 5vw;
  box-sizing: border-box;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: center;
  /* Card Style */
  box-shadow: 9px 2px 41.2px 6px rgba(0, 0, 0, 0.15);
}

.landing-page .signup-header .text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 35px;
  box-sizing: border-box;
  width: 40vw;
  max-width: 700px;
}

.landing-page .signup-header span {
  color: white;
}

.landing-page .signup-header .landing-button span {
  color: #447D00;
}

.landing-page .signup-header img {
  width: 50vw;
  height: auto;
  border-radius: 15px;
  max-height: 50vh;
}

.landing-page footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), var(--Color-Dark-Green, #447D00);
  min-width: 100vw;
  min-height: fit-content;
  height: 250px;
  gap: 10vh;
  padding: 5vh 10vw 3vh 10vw;
  box-sizing: border-box;
}

.landing-page footer .brand-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  color: white;
  height: 100%;
}

.landing-page footer img {
  width: 30vw;
  max-width: 215px;

}

.landing-page footer .links {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

.landing-page footer .links a {
  color: var(--Gray-Pure-White, #FFF);
  text-align: right;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 300;
  line-height: 123.5%; /* 25.935px */
  text-decoration: none;
}

.social-media-button {
  display: flex;
  padding: 12px 22px 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: var(--Gray-Gray-2, #F0EFED);
}

.social-media-button:hover {
  cursor: pointer;
}

/* landing.scss */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-section {
  opacity: 0;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-name: fadeIn;
}

.landing-page .horizontal-carousel .prev-page,
.landing-page .horizontal-carousel .next-page,
.landing-page .horizontal-carousel .main-page {
  border-radius: 12px;
}



/* FOR MOBILE */
@media screen and (max-width: 600px) {
  .landing-page-nav .actions {
    gap: 10px;
  }

  .landing-page .welcome-content {
    padding: 20px 10px 10vh 10px;
  }

  .landing-page .summary-content {
    padding: 10vh 10px;
  }

  .landing-page .summary-content .content .card {
    width: 43.5vw;
  }

  .landing-page .demo-content {
    padding: 10vh 10px;
  }

  .landing-page .logistics-content {
    padding: 10vh 10px;
  }

  .landing-page .footer-content {
    padding: 10px 10px 20px 10px;
  }

  /* .landing-page .signup-header {
  //   padding: 5vh 10px;
  //   width: 95vw;
   } */

  .landing-page .signup-header p {
    font-size: 20px;
  }

  .landing-page .teacher-demo .examples {
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    width: 100vw;
  }

  .landing-page footer {
    width: 95vw;
  }

  .landing-page footer .links a {
    font-size: 16px;
  }
}

@media screen and (max-width: 750px) {
  .landing-page .welcome-content .hero {
    flex-direction: column;
    justify-content: center;
  }

  .landing-page .welcome-content .hero .text-content {
    text-align: center;
    align-items: center;
    width: 100%;
  }

  .landing-page .welcome-content .hero video {
    min-width: 300px;
  }

  .landing-page .signup-header {
    padding: 20px 30px;
  }

  .landing-page .signup-header .text-content {
    max-width: 100%;
  }

  .landing-page .reading-container {
    height: 70vh;
    max-height: 70vh;
  }

  .landing-page .reading-container .app-button {
    width: 150px;
  }

  .landing-page h1 {
    font-size: 40px;
  }
}