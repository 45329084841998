.app-page {
  display: flex;
  position: relative;
  top: 70px;
  padding: 30px 40px 40px 40px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 36px 36px 0px 0px;
  border-top: 2px solid var(--pure-white);
  background: var(--gray-1);
  box-shadow: 0px 0px 16px 0px rgba(82, 75, 73, 0.12);
  min-height: var(--app-page-height);
  height: 100%;
  max-height: var(--app-page-height);
  width: 100vw;
  max-width: 100vw;
  overflow-x: auto;
}

.app-page-content {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  flex: 1 0 0;
  align-self: stretch;
  flex-grow: 1;
  min-height: var(--app-page-content-height);
  height: var(--app-page-content-height);
  max-height: var(--app-page-content-height);
  max-width: calc(100vw - 80px);
  min-width: 100%;
}

.app-main-content {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  align-self: stretch;
  border-radius: 24px;
  background: var(--pure-white);
  box-shadow: 0px 20px 60px 0px rgba(82, 75, 73, 0.20);
  min-height: var(--app-page-content-height);;
  height: var(--app-page-content-height);
  max-height: var(--app-page-content-height);
  max-width: 100%; 
  overflow: hidden;
  transition: all ease 1s;
  min-width: 500px;
}

.app-main-content h1 {
  display: flex;
  padding: 12px 26px 18px 26px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: #756F6D;
}

.join-classroom-panel {
  display: flex;
  padding: 28px 42px;
  justify-content: center;
  align-items: center;
  gap: 14px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--gray-2);
  color: var(--gray-10);
  text-align: center;
}

.join-classroom-panel:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), var(--gray-2);
  cursor: pointer;
}

.classroom-panel {
  display: flex;
  padding: 36px 42px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--dark-green);
  color: var(--pure-white);
  text-align: center;
  justify-content: space-between;
   
}

.classroom-panel:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), var(--dark-green);
  cursor: pointer;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
}

.col {
  display: flex;
  flex-direction: column;
}

.row.stretch {
  justify-content: space-evenly;
  align-self: stretch;
  /* flex: 1 1 auto; */
}

.row.spaced {
  justify-content: space-between;
  align-self: stretch;
  width: 100%;
  /* flex: 1 1 auto; */
}

.item-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 2px solid var(--gray-2);
  background: var(--gray-1);
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}

/* FOR MOBILE */
@media screen and (max-width: 700px) {
  .app-page {
    padding: 10px 10px;
  }

  .app-page-content {
    gap: 10px;
    min-height: var(--app-page-height-small);
    height: var(--app-page-content-height-small);
    max-height: var(--app-page-content-height-small);
  }

  .app-main-content {
    padding: 5px 5px;
    min-height: var(--app-page-content-height-small);
    height: var(--app-page-content-height-small);
    max-height: var(--app-page-content-height-small);
    min-width: calc(100vw - 20px);;
    width: calc(100vw - 20px);
    max-width: calc(100vw - 20px);
  }

  .app-main-content h1 {
    padding: 6px 10px 8px 10px;
    height: 80px;
  }

  .classroom-panel {
    padding: 10px 10px;
  }

  .join-classroom-panel {
    padding: 10px 10px;
  }

  .row {
    gap: 4vh;
  }
}