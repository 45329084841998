.book-card {
  width: 165px;
  height: 310px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.book-card:hover {
  cursor: pointer;
}

.book-card-cover {
  display: flex;
  width: 165px;
  height: 260px;
  flex-shrink: 0;
  border-radius: 6px;
  background: var(--gray-2);
  justify-content: center;
  align-self: stretch;
}

.book-card-cover img.icon {
  width: 50%;
}

.book-card-cover img {
  width: 100%;
  height: auto;
  border-radius: 6px;
}

.book-label {
  padding: 0px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  overflow-y: auto; 
}

/* FOR MOBILE */
@media screen and (max-width: 600px) {
  .book-card {
    width: 100px;
    height: 188px;
  }
  
  .book-card-cover {
    width: 100px;
    height: 157px;
  }
}