.classroom-list {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 2px solid var(--gray-2);
  background: var(--gray-1);
  min-height: fit-content;
   
  overflow-y: auto;
  height: 100%;
  max-height: 100%;
}

/* FOR MOBILE */
@media screen and (max-width: 600px) {
  .classroom-list {
    padding: 5px 5px;
  }
}