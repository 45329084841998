/* Table */
.progress-report-table {
  width: 100%;
  border: 1px solid #ddd;
}

/* Table Header */
.progress-report-table th {
  background-color: #f2f2f2;
  color: #333;
  text-align: left;
  padding: 12px;
  font-size: 16px;
}

/* Table Body */
.progress-report-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}

/* Table Rows */
.progress-report-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.progress-report-table tr:hover {
  background-color: #f1f1f1;
}

/* Table container margin for responsive design */
@media screen and (max-width: 600px) {
  .progress-report-table table, 
  .progress-report-table thead, 
  .progress-report-table tbody, 
  .progress-report-table th, 
  .progress-report-table td, 
  .progress-report-table tr {
      display: block;
  }

  .progress-report-table th {
      position: absolute;
      top: -9999px;
      left: -9999px;
  }

  .progress-report-table tr {
      border: 1px solid #ccc;
  }

  .progress-report-table td {
      border: none;
      border-bottom: 1px solid #ddd;
      position: relative;
      padding-left: 50%;
      text-align: right;
  }

  .progress-report-table td:before {
      position: absolute;
      top: 12px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
      font-weight: bold;
  }

  .progress-report-table td:nth-of-type(1):before { content: "Active"; }
  .progress-report-table td:nth-of-type(2):before { content: "Name"; }
  .progress-report-table td:nth-of-type(3):before { content: "Option"; }
}
