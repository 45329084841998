.welcome-panel {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
  flex: 1 0 0;
  align-self: stretch;
}

.welcome-panel img {
  width: 120px;
}

.labeled-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 6px;
  border: 2px solid var(--gray-7);
  background: var(--pure-white);
  flex-direction: row;
  
  align-self: stretch;
}

.labeled-input:focus-within {
  outline: -webkit-focus-ring-color auto 1px;
}

.labeled-input input {
  border: none !important;
  width: 100% !important;
}

.labeled-input input:focus-visible {
  outline: none;
}

.labeled-input.label-right {
  padding-right: 10px;
}

.labeled-input.label-left {
  padding-left: 10px;
}

.labeled-input.valid {
  outline: var(--dark-green) auto 1px;
}

.labeled-input.invalid {
  outline: var(--dark-pink) auto 1px;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}

/* material ui slider */

.css-1gv0vcd-MuiSlider-track {
  border-radius: 30px !important;
  color: var(--gray-4) !important;
}

.css-187mznn-MuiSlider-root {
  color: var(--gray-4) !important;
}

.css-eg0mwd-MuiSlider-thumb {
  color: var(--gray-4) !important;
  border-radius: 0px !important;
  height: 10px !important;
  width: 5px !important;
}

.css-eg0mwd-MuiSlider-thumb::after {
  color: var(--gray-4) !important;
  border-radius: 0px !important;
  height: 10px !important;
  width: 5px !important;
}

/* FOR MOBILE */
@media screen and (max-width: 700px) { 

}