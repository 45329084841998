.social-media-icon {
  width: 35px;
  height: 35px;
}

.social-media-icon:hover {
  cursor: pointer;
}

.contact-page {
  display: flex;
  flex-direction: column;
  gap: 25px;
}