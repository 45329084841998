/* For Small Screen App Header */

.hamburger-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 5px;
  background-color: var(--gray-2);
  align-self: flex-start;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu li:hover {
  background-color: var(--gray-3);
}

.dropdown-menu li a {
  text-decoration: none;
  color: black;
  display: block;
}

/* End Small Screen App Header */

/* Default App Header */

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--pure-white);
  z-index: 100;
  height: 50px;
  display: flex;
  padding: 15px 4vw;
  justify-content: space-between;
  align-items: center;
  gap: 1vw;
  align-self: stretch;
}

.fixed-header img {
  width: 114px;
  height: 40px;
  object-fit: contain;
}

.fixed-header .col {
  width: 30vw;
  min-width: 30vw;
  max-width: 30vw;
  align-items: center;
}

.fixed-header .landing-button img {
  height: 20px;
  width: auto;
}

.fixed-header .row {
  gap: 20px;
}

/* End Default App Header */