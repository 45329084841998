.loading-indicator {
  width: 150px;
  height: 100px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.loading-indicator svg {
  height: 100px;
}

.loading-indicator image {
  width: 120px;
  height: auto;
  align-self: center;
  position: relative;
}

.spinner {
  border: 4px solid rgba(0,0,0,.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: var(--dark-green);
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounceAndSquish {
  0% {
    transform: translateX(-150px) scale(0.9, 1); /* Start and end at -150px, slightly squished */
  }
  5% {
    transform: translateX(-120px) scale(1.15, 1); /* First expansion */
  }
  15% {
    transform: translateX(-90px) scale(0.9, 1); /* First squish */
  }
  25% {
    transform: translateX(-60px) scale(1.15, 1); /* Second expansion */
  }
  35% {
    transform: translateX(-30px) scale(0.9, 1); /* Second squish */
  }
  45% {
    transform: translateX(0px) scale(1.15, 1); /* Third expansion */
  }
  55% {
    transform: translateX(30px) scale(0.9, 1); /* Third squish */
  }
  65% {
    transform: translateX(60px) scale(1.15, 1); /* Fourth expansion */
  }
  75% {
    transform: translateX(90px) scale(0.9, 1); /* Fourth squish */
  }
  85% {
    transform: translateX(120px) scale(1.15, 1); /* Fifth expansion */
  }
  95%, 100% {
    transform: translateX(150px) scale(0.9, 1); /* End at 150px, slightly squished */
  }
}