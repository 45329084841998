.book-completed-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
  flex: 1 0 0;
  color: var(--dark-green);
}

.reading-footer {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  height: 60px;
  align-items: center;
  justify-content: center;
}