.assignment-editor-title {
  display: flex;
  padding: 12px 0px 18px 0px;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}

.optional-book-details {
  display: flex;
  padding: 12px 0px 18px 0px;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}

.book-details-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.book-details-editor input {
  flex: 1 0 0;
  height: 52px;
}

.optional-book-details select {
  height: 52px;
}

.book-details-editor input.title {
  font-size: 26px;
  line-height: 140%; /* 36.4px */
  letter-spacing: 0.52px;
}

.book-details-editor input.normal {
  font-size: 16px;
  line-height: 150%; /* 24px */
  letter-spacing: 0.64px;
}

.optional-book-details select.normal {
  font-size: 16px;
  line-height: 150%; /* 24px */
  letter-spacing: 0.64px;
}

.optional-book-details input.normal {
  height: 52px;
}

.assignment-editor-title img {
  width: 48px;
  height: 48px;
}

.book-details-editor .file-input {
  height: 52px;
}

.assignment-sidebar-content {
  display: flex;
  padding: 0px 5px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  overflow-y: auto; 
}

.assignment-sidebar-content .file-upload-button {
  height: 45px;
}

.assignment-attribute-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-top: solid 2px var(--gray-3);
  padding-top: 10px;
}

.from-to {
  display: flex;
  padding: 5px 0px;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
}

.assignment-sidebar-content input[type='text'], input[type='number'], input[type='datetime-local'] {
  font-size: 14px;
  line-height: 150%; /* 21px */
  letter-spacing: 0.56px;
  height: 45px;
  min-height: 45px;
  width: 100%;
}

.assignment-sidebar-content select {
  font-size: 14px;
  line-height: 150%; /* 21px */
  letter-spacing: 0.56px;
}

.assignment-sidebar-content .app-button {
  align-self: stretch;
}

.assignment-editor-actions {
  display: flex;
  padding: 12px 26px 0px 26px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

input#book-cover-upload {
  opacity: 0;
  max-width: 0px;
}