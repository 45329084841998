.text-editor {
  display: flex;
  align-self: stretch;
}

.quill {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: calc(100vh - 130px - 24px - 164px - 52px);
  width: 100%;
  border-radius: 12px;
}

.ql-container{
  overflow: auto;
  display: flex;
  padding: 26px;
  flex-direction: column;
  align-items: stretch;
  flex: 1 0 0;
  border-radius: 12px;
  border: 2px solid var(--gray-2);
  background: var(--gray-1);
}

.ql-editor {
  /* Body/Book Text */
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 250%; /* 50px */
  letter-spacing: 0.8px;
  position: relative;
}

.ql-editor.ql-blank::before {
  right: unset;
  left: unset;
}

.ql-toolbar {
  display: flex;
  padding: 15px 26px;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  border-radius: 12px;
  border-bottom: 1px solid rgba(82, 75, 73, 0.30);
  background: var(--gray-2);
}

/* Target the toolbar button by its class */
.ql-toolbar .ql-generateButton {
  /* Modern gradient background */
  background: linear-gradient(135deg, #6B52AE 0%, #D85095 100%);
  
  /* Text styling */
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  font-family: sans-serif;

  /* Spacing & sizing */
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  margin: 0 4px;

  /* Cursor & transitions */
  cursor: pointer;
  transition: background 0.3s ease, transform 0.1s ease;
}

/* Hover state: slightly darker gradient */
.ql-toolbar .ql-generateButton:hover {
  background: linear-gradient(135deg, #5B439C 0%, #C3397F 100%);
}

/* Active state: subtle button press effect */
.ql-toolbar .ql-generateButton:active {
  transform: scale(0.95);
}

.ql-toolbar .ql-generateButton::before {
  content: '🤖';
}

/* (Optional) If you'd like a small icon with text:
   Use a pseudo-element or inline SVG inside the button
   For example:
   
   .ql-toolbar .ql-myCustomButton::before {
     content: '🤖';
     margin-right: 6px;
   }
   
   Adjust as needed.
*/
