.multi-part-assignment-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5%; 
}

.multi-part-assignment-component textarea {
  width: 100%;
  height: 100%;
}