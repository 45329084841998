.book-catalog-content {
  display: flex;
  padding: 35px 0px;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  flex: 1 1 auto;
  align-self: stretch;
  border-radius: 12px;
  border: 2px solid var(--gray-2);
  background: var(--gray-1);
  overflow-y: auto;
  min-width: 100%;
}

.modal-content .reading-container {
  min-width: 80vw;
  min-height: 70vh;
}

.modal-content .app-page-content {
  max-width: 75vw;
  max-height: 65vh;
}

.modal-content .book-catalog-content {
  width: 58vw;
}

/* FOR MOBILE */
@media screen and (max-width: 600px) {
  .book-catalog-content {
    gap: 20px;
  }
}