.subscription-select {
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  align-items: center;
  width: 290px;
  justify-content: space-between;
  border: 2px solid var(--gray-3);
  padding: 20px;
  
}

.subscription-select.selected {
  border: 2px solid var(--dark-green);
}

.subscription-select:hover {
  cursor: pointer;
  background-color: #E1E1E0;
}

.subscription-select img {
  height: 70px;
}

.subscription-select:focus-within {
  border: 2px solid var(--dark-green);
}