.student-details {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  background: var(--gray-1);
  width: 70vw;
  max-height: 60vh;
  overflow: auto;
}

.student-details-header {
  display: flex;
  min-height: 86px;
  padding: 0px 18px 0px 28px;
  align-items: center;
  gap: 25px;
  align-self: stretch;
  border-radius: 12px 12px 0px 0px;
  border-bottom: 2px solid var(--gray-3);
  background: var(--dark-green);
  color: white
}

.student-details-header select {
  background-color: transparent;
  border: none;
  color: var(--pure-white);
  max-width: fit-content;
  max-height: fit-content;
}

.student-details-content {
  overflow: auto;
  align-self: stretch;
}

/* FOR MOBILE */
@media screen and (max-width: 600px) {
  .student-details {
    width: 95vw;
    max-width: 100%;
    height: fit-content;
  }
}