.app-button {
  display: inline-flex;
  padding: 5px;
  align-items: center;
  gap: 3px;
  border-radius: 12px;
  border: 2px solid var(--gray-3);
  background: var(--pure-white);
  color: var(--pure-black);
}

.button-content {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  border-radius: 7px 4px 4px 7px;
  border: none;
  border-bottom: 1px solid rgba(82, 75, 73, 0.30);
  background: var(--gray-2);
  font-size: 28px;
  line-height: 100%; /* 28px */
  letter-spacing: 1.12px;
  align-self: stretch;
}

.button-content img {
  width: 24px;
  height: 24px;
}

.button-content:hover {
  cursor: pointer;
}

.arrow-forward {
  transform: scaleX(-1);
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
}

.audio-assistance-button:hover {
  cursor: pointer;
}

.audio-assistance-button img {
  width: 15px !important;
}

.action-button {
  display: flex;
  height: 40px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 7px;
  border: none;
  border-bottom: 1px solid rgba(82, 75, 73, 0.30);
  color: var(--pure-black);
  box-shadow: 0px 1px 3px 0px rgba(82, 75, 73, 0.20);
  background: var(--gray-3)
}

.action-button img {
  width: 16px;
  height: 16px;
}

.action-button.go {
  background: var(--dark-green);
  color: var(--pure-white);
}

.action-button.edit {
  background: var(--dark-blue);
  color: var(--pure-white);
}

.action-button.delete {
  background: var(--dark-pink);
  color: var(--pure-white);
}

.action-button.option {
  background: var(--yellow);
  color: var(--pure-black)
}

.action-button:hover {
  cursor: pointer;
}

.action-button:disabled {
  background-color: var(--gray-3);
  cursor: auto;
}

.landing-button {
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  border: none;
  /* Shadow/Tiny Button/Static */
  box-shadow: 0px 1px 3px 0px rgba(82, 75, 73, 0.20);
}

.landing-button span {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.landing-button:hover {
  cursor: pointer;
}

.landing-button.black {
  background: var(--pure-black);
  color: var(--pure-white);
}

.landing-button.white {
  background: var(--pure-white);
  color: var(--pure-black);
}

.landing-button.green {
  background: var(--dark-green);
  color: var(--pure-white);
}

.landing-button.grey {
  border-bottom: 1px solid rgba(82, 75, 73, 0.30);
  background: var(--gray-2);
}

.landing-button.blue {
  background: var(--dark-blue);
  color: var(--pure-white);
}

.landing-button img {
  width: 20px !important;
  height: 20px !important;
}

.icon-button {
  display: flex;
  padding: 6px;
  align-items: center;
  border-radius: 4px;
  background: var(--gray-2);
  border: none;
}

.icon-button:hover {
  cursor: pointer;
  background: var(--gray-3);
}

.icon-button.transparent {
  background: transparent;
}

.sidebar-button {
  display: flex;
  flex-direction: row;
  height: 100px;
  padding: 20px 42px;
  justify-content: center;
  align-items: center;
  justify-content: left;
  gap: 14px;
  align-self: stretch;
  border-radius: 7px;
  text-align: center;
  border: none;
}

.sidebar-button img {
  width: 24px;
  height: 24px;
}

.sidebar-button:hover {
  cursor: pointer;
}

.sidebar-button.go {
  background: var(--dark-green);
  color: var(--pure-white);
}

.sidebar-button.go:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), var(--dark-green);
}

.sidebar-button.info {
  background: var(--gray-2);
  color: var(--gray-10);
}

.sidebar-button.info:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), var(--gray-2);
}

/* FOR MOBILE */
@media screen and (max-width: 700px) { 
  .button-content {
    font-size: 18px;
  }
}