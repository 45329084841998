.item-panel {
  display: flex;
  height: 90px;
  padding: 24px 18px 24px 28px;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  align-self: stretch;
  border-bottom: 2px solid var(--gray-2);
}

.item-panel.expanded {
  flex-direction: column;
  border-bottom: 2px solid var(--gray-7);
  background: var(--gray-3);
  height: fit-content;
  align-items: unset;
  overflow-y: auto;
}

.item-panel:hover {
  background: var(--gray-2); 
  cursor: pointer;
}

.item-panel.selected {
  background: var(--dark-green);
  color: var(--pure-white);
}

.item-panel.selected:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), var(--dark-green);
  cursor: pointer;
}

.item-panel.highlighted {
  box-shadow: 0 0 30px var(--dark-green);
  z-index: 1000;
}