.status-icon {
  display: flex;
  padding: 4px 14px 4px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  border-bottom: 1px solid var(--gray-4);
  background: var(--pure-white);
}

.status-icon img {
  width: 16px;
}

.status-icon.clickable {
  cursor: pointer;
}