.reading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  max-width: 100%;
  height: calc(100vh - 130px - 24px - 80px);
  max-height: 100%; /* calc(100vh - 130px - 24px - 80px); */
  min-height: calc(100vh - 130px - 24px - 80px);
  gap: 12px;
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
}

.reading-container .app-button {
  width: 200px;
  min-width: fit-content;
}

.reading-frame {
  display: flex;
  flex-direction: column;
  padding: 60px 80px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 2px solid var(--gray-2);
  background: var(--gray-1);
  color: var(--gray-10);
  font-size: calc(20px * var(--font-scale));
  line-height: calc(250% * var(--line-spacing-scale));
  letter-spacing: 0.8px;
  overflow-y: auto;
  height: 100%;
}

.reading-frame.dyslexic * {
  font-family: 'OpenDyslexic' !important; 
}

.reading-frame .ql-size-huge {
  font-size: calc(2.5em * var(--font-scale));
}

.reading-frame .ql-size-large {
  font-size: calc(1.5em * var(--font-scale));
}

.reading-frame .ql-size-small {
  font-size: calc(0.75em * var(--font-scale));
}

.reading-frame blockquote {
  border-left: 4px solid var(--gray-3);
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

.reading-frame.blurred {
  opacity: 0.5;
  filter: blur(6px);
  align-self: stretch;
}

.brw.incorrect {
  text-decoration: underline 3px var(--yellow);
}

.brw.current {
  color: black;
  font-weight: var(--font-weight-bold);
  transform: scale(1.1);
  /* border-bottom: 2px solid #F0EFED; */
  /* text-decoration: underline 3px #F0EFED; */
}

.brw.read {
  color: var(--gray-8);
  font-weight: var(--font-weight);
}

.questions-viewer {
  display: flex;
  min-width: 70vw;
  padding: 2px;
  align-items: flex-start;
  border-radius: 24px;
  border: 3px solid var(--pure-white);
  background: var(--pure-white);
  min-height: 50vh;
  overflow: auto;
  flex-wrap: wrap;
}

.questions-viewer-sidebar-container {
  display: flex;
  width: 260px;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  max-width: 80vw;
}

.questions-viewer-sidebar {
  display: flex;
  padding: 2px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 2px solid var(--gray-2);
  gap: 30px;
}

.questions-viewer-sidebar .question-list {
  align-self: stretch;
}

.questions-viewer-content {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  flex: 1 0 0;
  border-radius: 12px;
  background: var(--gray-1);
}

.questions-viewer-content label {
  color: var(--gray-10);
  text-align: center;
}

.questions-viewer .grade-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border: 2px solid var(--gray-2);
  border-radius: 6px;
  padding: 20px;
}

.questions-viewer .grade-input input {
  width: 50px;
  padding: 3px;
}

.question-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}

.question-input label {
  color: var(--gray-10);
}

.question-input textarea {
  color: var(--gray-10);

  /* Body/Book Text */
  font-size: 20px;
  line-height: 250%; /* 50px */
  letter-spacing: 0.8px;
  
  flex: 1 0 0;
  display: flex;
  min-height: 240px;
  max-height: 240px;
  overflow-y: scroll;
  padding: 8px 24px;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  border: 2px solid var(--gray-7);
  background: var(--pure-white);
}

.questions-viewer-content .app-button {
  align-self: stretch;
}

.question-select {
  display: flex;
  padding: 12px 22px 12px 18px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 2px solid var(--gray-2);
  color: var(--gray-8);
}

.question-select label {
  flex: 1 0 0;
}

.question-select.selected {
  border-right: solid 10px var(--dark-green);
  border-radius: 6px 6px;
}

.missed-word-popup {
  display: flex;
  padding: 12px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: center;
  border-radius: 12px;
  background: var(--gray-1);
  height: 50vh;
  width: 40vw;
  min-width: 500px;
  max-height: 50vh;
  border-radius: 24px;
  border: 3px solid var(--pure-white);
  position: relative;
  gap: 3%;
  perspective: 1000px; /* for the flip effect */
  transform-style: preserve-3d; /* Allows for actual 3D transformations */
  transform-origin: center;
  /* Shadow/Section/Main Card */
  box-shadow: 0px 20px 60px 0px rgba(82, 75, 73, 0.20);
}

.missed-word-popup .icon-button {
  position: absolute;
  top: 8px;
  right: 12px;
  width: 35px;
  height: 35px;
}

.missed-word-popup .front, .missed-word-popup .back {
  top: 15%;
  left: 12px;
  width: calc(100% - 24px);
  height: calc(85% - 12px);
  position: absolute;
  backface-visibility: visible;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  transition: transform 1s; 
}

.missed-word-popup .back {
  transform: rotateY(180deg);
}

.missed-word-popup-content {
  display: flex;
  padding: 12px 24px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 0px 0px 10px 10px;
  border: 2px solid var(--gray-7);
  background: var(--pure-white);
  color: var(--gray-9);
  height: 100%;
  max-height: 42vh;
}

.missed-word-definition {
  text-align: left;
  overflow-y: auto;
  align-self: stretch;
}

.missed-word-popup img {
  width: 50px;
}

.word-known-prompt {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-evenly;
}

.word-known-prompt .app-button {
  width: fit-content;
  height: fit-content;
}

.book-emoji {
  display: flex;
  padding: 40px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  align-self: center;
  border-radius: 50%;
  background: var(--pure-white);
  width: 130px;
  height: 130px;
  /* Shadow/Section/Main Card */
  box-shadow: 0px 20px 60px 0px rgba(82, 75, 73, 0.20);
}

.book-emoji img {
  width: 20vw;
}

/* FOR MOBILE */
@media screen and (max-width: 600px) {
  .modal .reading-container {
    width: 98vw;
    height: 98vh;
  }

  .reading-frame {
    padding: 30px 40px;
  }

  .missed-word-popup {
    min-width: 80vw;
    min-height: 50vh;
  }

  .missed-word-popup-content {
    min-height: 42vh;
  }

  .book-completed-text {
    width: 18vw;
  }

  .book-completed-text .app-button {
    width: 15vw;
  }
}

@media screen and (max-height: 650px) {
  .reading-frame {
    padding: 30px 40px;
  }
}