input {
  color: var(--gray-10);
}

input[type='text'], input[type='number'], input[type='datetime-local'], input[type='password'], input[type='email'] {
  display: flex;
  padding: 9px 12px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  border: 2px solid var(--gray-7);
  background: var(--pure-white);
}

textarea {
  display: flex;
  padding: 9px 12px;
  border-radius: 6px;
  border: 2px solid var(--gray-7);
  background: var(--pure-white);
  align-items: center;
  gap: 10px;
  align-self: stretch;
  max-width: 100%;
}

input[type='number'] {
  width: 60px;
  padding-right: 4px;
}

input[type='file'] {
  border: none;  
  padding: 0;
  display: none;
}

select {
  display: flex;
  padding: 9px 12px;
  align-items: center;
  align-self: stretch;
  /* flex: 1 0 0; */
  border-radius: 6px;
  border: 2px solid var(--gray-7);
  background: var(--pure-white);
  color: var(--gray-10);
}