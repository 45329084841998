@font-face {
  font-family: 'OpenDyslexic';
  src: url('./assets/fonts/opendyslexic/OpenDyslexic-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenDyslexic';
  src: url('./assets/fonts/opendyslexic/OpenDyslexic-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'OpenDyslexic';
  src: url('./assets/fonts/opendyslexic/OpenDyslexic-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

* {
  /* Colors */
  --dark-blue: #016D89;
  --dark-green: #447D00;
  --dark-pink: #B1243F;
  --gray-1: #FAFAF7;
  --gray-2: #F0EFED;
  --gray-3: #E1E1E0;
  --gray-4: #D4D4D4;
  --gray-7: #A8A5A4; 
  --gray-8: #979392;
  --gray-9: #756F6D;
  --gray-10: #524B49;
  --pure-black: #000;
  --pure-white: #FFF;
  --yellow: #FCF095;

  /* Sizing */
  --app-page-height: calc(100vh - 70px);
  --app-page-content-height: calc(var(--app-page-height) - 60px);
  --app-page-content-height-small: calc(var(--app-page-height) - 25px);
  --app-sidebar-width: 400px;
  box-sizing: border-box;

  /* Text */
  font-family: 'Poppins', 'OpenDyslexic';
  --font-weight: 500;
  --font-weight-bold: 700;
  --font-scale: 1;
  --line-spacing-scale: 1;
}

body {
  /* Text */
  color: var(--gray-10);
  font-weight: 500;
  font-style: normal;
}