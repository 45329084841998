.book-catalog-row-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 90%;
  max-width: 90%;
  flex: 1 1 auto;
}

.book-catalog-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 45px;
  height: 340px;
  padding-bottom: 12px;
  transition: all 0.5s ease-in-out;
}

.book-catalog-row.expand {
  flex-wrap: wrap;
  flex: 1 0 auto;
  height: fit-content; 
  min-height: fit-content;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* FOR MOBILE */
@media screen and (max-width: 600px) {
  .book-catalog-row {
    height: 33vh;
  }
}