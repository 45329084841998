.word-list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}

.word {
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: var(--gray-2);
  border-radius: 12px;
  padding: 5px 8px;
  align-items: center;
  animation: poofAppear 0.6s ease-out forwards;
}

.word .x:hover {
  cursor: pointer;
}

.word.clickable:hover {
  cursor: pointer;
  transform: scale(1.05)
}