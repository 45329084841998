.speech-bubble {
  width: 80%;
  background: transparent;
  padding: 5px;
  text-align: center;
  position: relative;
  border: solid var(--gray-10);
  border-radius: 10px;
  z-index: 1;
}

.speech-bubble.correct {
  animation: glow-green 2s ease-out;
}

@keyframes glow-green {
  0% {
    box-shadow: 0 0 0px rgba(0, 255, 0, 0);
  }
  50% {
    box-shadow: 0 0 30px var(--dark-green);
  }
  100% {
    box-shadow: 0 0 0px rgba(0, 255, 0, 0);
  }
}

.sb3:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid var(--gray-10);
  border-right: 10px solid transparent;
  border-top: 9px solid var(--gray-10);
  border-bottom: 10px solid transparent;
  left: 19px;
  bottom: -19px;
}