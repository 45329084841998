h1 {
  font-size: 36px;
  font-weight: 500;
  line-height: 140%; /* 50.4px */
  letter-spacing: 1.44px;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 32px;
  font-weight: 500;
  line-height: 140%; /* 44.8px */
  letter-spacing: 0.64px;
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 26px;
  font-weight: 500;
  line-height: 140%; /* 36.4px */
  letter-spacing: 0.52px;
  margin: 0;
  padding: 0;
}

.label-xl {
  font-size: 28px;
  line-height: 100%; /* 28px */
  letter-spacing: 1.12px;
}

.label-large {
  font-size: 22px;
  line-height: 100%; /* 22px */
  letter-spacing: 0.88px;
}

.label-medium {
  font-size: 18px;
  line-height: 160%; /* 28.8px */
  letter-spacing: 0.72px;
}

.label-normal {
  font-size: 16px;
  line-height: 150%; /* 24px */
  letter-spacing: 0.64px;
}

.label-small {
  font-size: 14px;
  line-height: 150%; /* 21px */
  letter-spacing: 0.56px;
}

.label-xs {
  font-size: 12px;
  line-height: 150%; /* 21px */
  letter-spacing: 0.48px;
}

.label-hint {
  color: var(--dark-green);
}

.fine-print {
  display: flex;
  color: var(--gray-7);
  align-items: center;
  font-size: 11px;
  line-height: 150%; /* 13.5px */
  letter-spacing: 0.36px;
  align-self: stretch;
}

.book-text {
  font-size: 20px;
  line-height: 250%;
  letter-spacing: 0.8px;
}

.fine-print.danger {
  color: var(--dark-pink);
}

.fine-print.success {
  color: var(--dark-green)
}

.danger {
  color: var(--dark-pink);
}

/* FOR MOBILE */
@media screen and (max-width: 600px) {
  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 16px;
  }

  .label-xl {
    font-size: 18px;
  }

  .label-large {
    font-size: 14px;
  }

  .label-medium {
    font-size: 12px;
  }

  .label-normal {
    font-size: 10px;
  }

  .label-small {
    font-size: 8px;
  }

  .label-xs {
    font-size: 6px;
  }
}