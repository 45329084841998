@keyframes poofAppear {
  0% {
    opacity: 0;
    transform: scale(0.5) rotate(-10deg);
    filter: blur(5px);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.1) rotate(5deg);
    filter: blur(2px);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0deg);
    filter: blur(0px);
  }
}
