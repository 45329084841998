.horizontal-carousel {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

/* Main page styling */
.main-page {
  z-index: 5;
  display: inline-block;
  position: relative;
  width: fit-content;
  height: fit-content;
  opacity: 1;                   /* Explicitly set full opacity */
  filter: none;                 /* Remove any inherited filter effects */
  -webkit-backface-visibility: hidden; /* Vendor prefix for WebKit */
  backface-visibility: hidden;
  transform: rotateY(0deg) translateZ(0); /* translateZ(0) can help force a new layer */
  transform-style: preserve-3d;
  will-change: transform;       /* Hint to the browser for performance */
  box-sizing: border-box;
  overflow: hidden;
}


/* When flipping, add the appropriate animation */
.flip-next {
  animation: flipNext 800ms forwards;
}

.flip-prev {
  animation: flipPrev 800ms forwards;
}

/* Keyframes for a “next” flip (rotate left and then come in from the right) */
@keyframes flipNext {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(-90deg);
  }
  /* Jump to the opposite side */
  50.01% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

/* Keyframes for a “previous” flip (rotate right and then come in from the left) */
@keyframes flipPrev {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(90deg);
  }
  /* Jump to the opposite side */
  50.01% {
    transform: rotateY(-90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

/* Styling for the preview pages on the sides */
.horizontal-carousel .prev-page, 
.horizontal-carousel .next-page {
  z-index: 1;
  position: absolute;
  top: 50%;
  width: fit-content;
  height: fit-content;
  transform: scale(0.5);
  filter: blur(4px);
  transition: transform 0.8s ease-out, filter 0.8s ease-out, opacity 0.8s ease-out;
  opacity: 0.5; /* Reduced opacity to make them less prominent */
  overflow: hidden;
}

.horizontal-carousel .prev-page {
  right: 50%;
  transform: translateY(-50%) scale(0.6);
}

.horizontal-carousel .next-page {
  left: 50%;
  transform: translateY(-50%) scale(0.6);
}
