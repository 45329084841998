.image-choice {
  display: flex;
  justify-content: center;
  width: 200px;
  height: 200px;
  padding: 20px;
  border: 2px solid var(--gray-3);
  border-radius: 12px;
  flex: 1 1 0;
}

.image-choice.expanded {
  flex-direction: column;
  border-bottom: 2px solid var(--gray-7);
  background: var(--gray-3);
  height: fit-content;
  align-items: unset;
  overflow-y: auto;
}

.image-choice:hover {
  background: var(--gray-2); 
  cursor: pointer;
}

.image-choice.selected {
  background: var(--dark-green);
  color: var(--pure-white);
}

.image-choice.selected:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), var(--dark-green);
  cursor: pointer;
}

.image-choice.highlighted {
  box-shadow: 0 0 30px var(--dark-green);
  z-index: 1000;
}

.image-choice.incorrect {
  border-color: var(--dark-pink);
}