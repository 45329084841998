.assignment-details-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 12px;
  flex: 1 0 0;
}

.assignment-details-container hr {
  width: 250px;
  height: 1px;
  background: var(--gray-3);
}

.assignment-detail-header {
  color: var(--dark-green);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
}

.assignment-details-list {
  display: flex;
  padding-top: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.assignment-detail {
  color: var(--gray-9);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  border-bottom: solid 1px var(--gray-3);
}

.assignment-detail b {
  font-size: 14px;
  line-height: 150%; /* 21px */
  letter-spacing: 0.56px;
}

/* FOR MOBILE */
@media screen and (max-width: 600px) {
 
}