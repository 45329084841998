.form-contents {
  display: flex;
  padding: 0px 18px;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  align-self: stretch;
}

.form {
  color: var(--gray-10);
  text-align: center;
  display: flex;
  width: 100%;
  max-width: 90vw;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  
}

.form .app-button{
  align-self: stretch;
}

.form .fine-print {
  align-self: center;
}