.practice-modal {
  width: 80vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.flashcard {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 400px;
  max-width: 100%;
  max-height: 100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
  background-color: var(--pure-white);
  border: thin solid var(--gray-10);
  box-shadow: 0px 20px 60px 0px rgba(82, 75, 73, 0.20);
}

.flashcard .icon-button {
  position: absolute;
  top: 10px;
  right: 10px;
}