.account-type-select {
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  align-items: center;
  width: 290px;
  justify-content: space-between;
  border: 2px solid var(--gray-3);
  padding: 20px;
  height: 95px;
}

.account-type-select.selected {
  border: 2px solid var(--dark-green);
}

.account-type-select:hover {
  cursor: pointer;
  background-color: var(--gray-3);
}

.account-type-select img {
  width: 80px;
  max-height: 70px;
}